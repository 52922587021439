import * as Sentry from '@sentry/browser';

Sentry.init({
  // RELEASE_FOR_SENTRY is replaced in webpack construction with git SHA commit value
  release: process.env.RELEASE,
  dsn: 'https://d8135b1695b34b95a9d9e89b67aed723@o93220.ingest.sentry.io/6276779',
  integrations: [new Sentry.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.01,
  sampleRate: 0.01,
  environment: 'prod-frontend',
});
